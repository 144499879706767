import React, { useState, useEffect } from "react"
import ReactPlayer from "react-player"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { bg } from "date-fns/locale"
import ReactDOM from "react-dom"
import Countdown from "../components/countdown"
import Map from "../components/map"

const Mihinthalaya = ({ data }) => {
  const images = {
    header: convertToBgImage(getImage(data.header)),
    mobile_bg: convertToBgImage(getImage(data.mobile_bg)),
  }
  const targetDate = "2023-06-02T20:30:00"

  return (
    <>
      <Layout>
        <Seo title="සදහම් සත්කාර  | Watawala" />
        <section>
          <div className="sm:block hidden ">
            <BackgroundImage
              {...images.header}
              className="  responsive-header relative bg-no-repeat  backimage "
              style={{ backgroundPosition: "left bottom" }}
            >
              {/* <div className="absolute right-0 bottom-0  ">
                <StaticImage
                  src="../images/pages/sadaham-sathkara/art.png"
                  class="w-full h-full bg-cover"
                  alt="watawala"
                />
              </div> */}
              <div className=" h-full ">
                <div className="container mx-auto h-full  md:px-20 px-10  ">
                  <div className="flex xl:flex-row flex-col  items-center  justify-center xl:justify-between h-full  relative">
                    <div className="flex  justify-center ">
                      <div className="2xl:pt-0 pt-20">
                        <div className="flex lg:justify-start justify-center md:mt-0 mt-10">
                          <StaticImage
                            src="../images/pages/sadaham-sathkara/title.png"
                            className="2xl:w-1/3 w-1/4"
                            alt="watawala"
                          />
                        </div>

                        <Countdown targetDate={targetDate} />

                        <div className=" lg:text-left text-center">
                          <div className="font-bold 2xl:text-6xl  text-4xl mt-10">
                            වටවල සදහම් සත්කාර
                          </div>
                          <div className=" max-w-sm mt-10 font-light text-lg lg:mx-0 mx-auto ">
                            මිහින්තලේ වඳින්න එන ඔබට වටවල සදහම් සත්කාර වෙතින්
                            පිදෙන අපූරු තිළිණය.ඔබත් පෙර නොතිබුණු මේ අත්දැකීම ගැන
                            දැනගන්න බලාපොරොත්තුවෙන් ඉන්න.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </BackgroundImage>

            <section>
              <div className="">
                <Map />
              </div>
            </section>
          </div>

          <div className="sm:hidden block">
            <BackgroundImage
              {...images.mobile_bg}
              className="  responsive-header relative    "
              style={{ backgroundPosition: "right ", opacity: 0.5 }}
            >
              {/* <div className="absolute right-0 bottom-0  ">
                <StaticImage
                  src="../images/pages/sadaham-sathkara/art.png"
                  class="w-full h-full bg-cover"
                  alt="watawala"
                />
              </div> */}
              <div className=" h-full ">
                <div className="container mx-auto h-full md:px-20 px-10 ">
                  <div className="flex xl:flex-row flex-col  items-center  justify-center xl:justify-between h-full  relative">
                    <div className="flex  justify-center ">
                      <div>
                        <div className="flex lg:justify-start justify-center md:mt-0 mt-10">
                          <StaticImage
                            src="../images/pages/sadaham-sathkara/title.png"
                            className="lg:w-1/3 w-1/2"
                            alt="watawala"
                          />
                        </div>

                        <Countdown targetDate={targetDate} />

                        <div className="lg:text-left text-center">
                          <div className="font-bold md:text-6xl text-5xl mt-10">
                            වටවල සදහම් සත්කාර
                          </div>
                          <div className=" max-w-sm mt-10 font-light text-lg lg:mx-0 mx-auto ">
                            මිහින්තලේ වඳින්න එන ඔබට වටවල සදහම් සත්කාර වෙතින්
                            පිදෙන අපූරු තිළිණය.ඔබත් පෙර නොතිබුණු මේ අත්දැකීම ගැන
                            දැනගන්න බලාපොරොත්තුවෙන් ඉන්න.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </BackgroundImage>
          </div>
        </section>
        {/* <section>
          <div className="relative">
            <StaticImage
              src="../images/pages/sadaham-sathkara/header.png"
              className="bg-cover h-full w-full"
            />
            <div className="absolute inset-0 flex flex-col items-center">
              <div className=" h-full ">
                <div className="container mx-auto h-full md:px-20 px-10 ">
                  <div className="flex xl:flex-row flex-col  items-center  justify-center xl:justify-between h-full  relative">
                    <div className="flex  justify-center ">
                      <div>
                        <div className="flex lg:justify-start justify-center md:mt-0 mt-10">
                          <StaticImage
                            src="../images/pages/sadaham-sathkara/title.png"
                            className="lg:w-1/3 w-1/2"
                            alt="watawala"
                          />
                        </div>

                        <Countdown targetDate={targetDate} />

                        <div className="lg:text-left text-center">
                          <div className="font-bold md:text-6xl text-5xl mt-10">
                            වටවල සදහම් සත්කාර
                          </div>
                          <div className=" max-w-sm mt-10 font-light text-lg lg:mx-0 mx-auto ">
                            මිහින්තලේ වඳින්න එන ඔබට වටවල සදහම් සත්කාර වෙතින්
                            පිදෙන අපූරු තිළිණය.ඔබත් පෙර නොතිබුණු මේ අත්දැකීම ගැන
                            දැනගන්න බලාපොරොත්තුවෙන් ඉන්න.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </Layout>
    </>
  )
}

export default Mihinthalaya
export const query = graphql`
  query ComingSoonImages {
    header: file(relativePath: { eq: "pages/sadaham-sathkara/header.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    mobile_bg: file(
      relativePath: { eq: "pages/sadaham-sathkara/mobile_bg.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
