import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import { graphql, Link } from "gatsby"

const Countdown = ({ targetDate }, data) => {
  const images = {
    back: convertToBgImage(getImage(data.back)),
  }

  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date()
    let timeLeft = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      }
    }

    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft())
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  return (
    <div className="flex gap-4 lg:justify-start justify-center mt-10">
      <div className="md:w-24 w-16 md:h-32 h-20 text-5xl font-bold flex items-center justify-center shadow-md rounded-2xl border-2 border-yellow bg-gradient-to-t from-[#F0BB00] to-[#FFCD03] ">
        <div className=" flex flex-col justify-between items-center">
          <div className="md:text-5xl text-4xl font-semibold">
            {timeLeft.days < 10 ? `0${timeLeft.days}` : timeLeft.days}
          </div>
          <div className="md:text-2xl text-base">දින</div>
        </div>
      </div>
      <div className="md:w-24 w-16 md:h-32 h-20 text-5xl font-bold flex items-center justify-center shadow-md rounded-2xl border-2 border-yellow bg-gradient-to-t from-[#F0BB00] to-[#FFCD03] ">
        <div className=" flex flex-col justify-between items-center">
          <div className="md:text-5xl text-4xl font-semibold">
            {timeLeft.hours < 10 ? `0${timeLeft.hours}` : timeLeft.hours}
          </div>
          <div className="md:text-2xl text-base">පැය</div>
        </div>
      </div>
      <div className="md:w-24 w-16 md:h-32 h-20 text-5xl font-bold flex items-center justify-center shadow-md rounded-2xl border-2 border-yellow bg-gradient-to-t from-[#F0BB00] to-[#FFCD03] ">
        <div className=" flex flex-col justify-between items-center">
          <div className="md:text-5xl text-4xl font-semibold">
            {timeLeft.minutes < 10 ? `0${timeLeft.minutes}` : timeLeft.minutes}
          </div>
          <div className="md:text-2xl text-base">මිනි</div>
        </div>
      </div>
      <div className="md:w-24 w-16 md:h-32 h-20 text-5xl font-bold flex items-center justify-center shadow-md rounded-2xl border-2 border-yellow bg-gradient-to-t from-[#F0BB00] to-[#FFCD03] ">
        <div className=" flex flex-col justify-between items-center">
          <div className="md:text-5xl text-4xl font-semibold">
            {timeLeft.seconds < 10 ? `0${timeLeft.seconds}` : timeLeft.seconds}
          </div>
          <div className="md:text-2xl text-base">තත්</div>
        </div>
      </div>
    </div>
  )
}

export default Countdown

export const query = graphql`
  query CountdownImages {
    back: file(relativePath: { eq: "backgrounds/back.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
